import React, { useEffect, Fragment } from "react";
import { scrollToElement } from "../../../../utility/utils";
import ContactFold from "../../general/contactFold";
import CTAUk from "../../general/ctaUk";

const entryContent = {
    title: "Contact Us",
    subtitle: "If you need help, you can chat with us on the app or send a message to helpuk@kuda.com.",
    mail: "helpuk@kuda.com"
}

const ContactUk = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <ContactFold title={entryContent.title} subtitle={entryContent.subtitle} supportMail={entryContent.mail}
            tel={'+44 203 835 4195'}
            />
            <CTAUk />
        </Fragment>
    )
}

export default ContactUk;
